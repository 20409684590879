import axios from "axios";

export const checkLogin = async (id) => {
    return await axios.get(`http://localhost:4000/user/login?userId=${id}`)
    .then((res) => res.data);
};

export const getUserList = async () => {
    return await axios.get("http://localhost:4000/user/list")
    .then((res) => res.data);
};

export const addUser = async (value) => {
    return await axios.post("http://localhost:4000/user/add", value)
    .then((res) => res.data);
};

export const changeUserInfo = async (value) => {
    return await axios.put("http://localhost:4000/user/info", value)
    .then((res) => res.data);
};

export const changePassword = async (value) => {
    return await axios.put("http://localhost:4000/user/passwd", value)
    .then((res) => res.data);
};

export const deleteUser = async (id) => {
    return await axios.delete(`http://localhost:4000/user/delete?id=${id}`)
    .then((res) => res.data);
};
