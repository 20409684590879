import { useReducer, createContext, useContext } from "react";
import { User } from "models/User";

function reducer(state, action) {
    switch(action.type) {
        case "LOGIN":
            return new User(action.id, action.name, action.icon, action.role);
        //case "LOGOUT":
        default:
            return null;
    }
}

const UserStateContext = createContext(null);
const UserDispatchContext = createContext(null);

export const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, null);
    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
};

export const useUserState = () => useContext(UserStateContext);
export const useUserDispatch = () => useContext(UserDispatchContext);
