import styled from "styled-components";
import UserSection from "./UserSection";
import {
    FiUser,
    FiKey,
    FiLogOut,
} from "react-icons/fi";

const TopBar = () => {    
    return (
        <TopBarContainer>
            <TitleContainer>
                <LogoImage />
                Hamster School Courseware
            </TitleContainer>
            <UserContainer>
                <UserSection />
            </UserContainer>
        </TopBarContainer>
    );
};

const TopBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #1976d2;
    color: #fff;
    height: 56px;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 8px;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
`;

const LogoImage = styled(FiUser)`
    margin-left: 8px;
    margin-right: 8px;
`;

const VersionContainer = styled.div`
    font-size: 1rem;
    margin-left: 8px;
`;

const UserContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export default TopBar;