export class User {
    constructor(id, name, icon, role) {
        this.id = id;
        this.name = name;
        this.icon = icon;
        this.role = role;
        this.isSuperUser = role === "su";
    }
}

export const ROLE_SUPER_USER = "su";
export const ROLE_USER = "user";
