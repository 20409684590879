import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
    FiLogOut,
} from "react-icons/fi";

import { useUserDispatch, useUserState } from "contexts/UserContext";
import { checkLogin } from "utils/Database";
import BubbleMenu from "pages/common/BubbleMenu";
import LoginDialog from "./LoginDialog";
import NewUserDialog from "./NewUserDialog";

const UserSection = () => {
    const [openLogin, setOpenLogin] = useState(false);
    const [openNewUser, setOpenNewUser] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatchUser = useUserDispatch();
    const user = useUserState();
    const navigate = useNavigate();

    const onClickLogin = () => {
        setOpenLogin(true);
    };

    const onClickUser = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onCloseMenu = () => {
        setAnchorEl(null);
    };

    const onSelectLogout = () => {
        setAnchorEl(null);
        if(dispatchUser) {
            if(user?.isSuperUser) {
                navigate("/");
            }
            dispatchUser({
                type: "LOGOUT",
            });
        }
    };

    const onSuccessLoginDialog = async (values) => {
        console.log(values);
        setOpenLogin(false);
        const result = await checkLogin(values.email);
        if(result) {
            if(dispatchUser) {
                dispatchUser({
                    type: "LOGIN",
                    id: result.userId,
                    name: result.userName,
                    icon: values.picture,
                    role: result.role,
                });
            }
        } else {
            setOpenNewUser(true);
        }
    };

    const onCancelLoginDialog = () => {
        setOpenLogin(false);
    };

    const onSuccessNewUserDialog = () => {
        setOpenNewUser(false);
    };

    const onCancelNewUserDialog = () => {
        setOpenNewUser(false);
    };

    return (
        <Section>
            {user ? (
                <>
                    <Tooltip title={<>{user.name}<br />{user.id}</>}>
                        <IconButton sx={{ padding: 0 }} onClick={onClickUser}>
                            <Avatar
                                alt={user.name}
                                src={user.icon}
                            />
                        </IconButton>
                    </Tooltip>
                    <BubbleMenu
                        anchorEl={anchorEl}
                        onClose={onCloseMenu}
                    >
                        <MenuItem onClick={onSelectLogout}>
                            <ListItemIcon>
                                <FiLogOut />
                            </ListItemIcon>
                            <FormattedMessage id="topbar_logout" />
                        </MenuItem>
                    </BubbleMenu>
                </>
            ) : (
                <>
                    <Button
                        color="inherit"
                        variant="outlined"
                        style={{
                            fontWeight: 600,
                        }}
                        onClick={onClickLogin}
                    >
                        <FormattedMessage id="topbar_login" />
                    </Button>
                    <LoginDialog
                        open={openLogin}
                        onSuccess={onSuccessLoginDialog}
                        onClickCancel={onCancelLoginDialog}
                    />
                    <NewUserDialog
                        open={openNewUser}
                        onSuccess={onSuccessNewUserDialog}
                        onClickCancel={onCancelNewUserDialog}
                    />
                </>
            )}
        </Section>
    );
};

const Section = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 16px;
`;

export default UserSection;