import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from "styled-components";
import { Link } from "react-router-dom";

const NavTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "black",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "black",
        padding: "8px",
    },
    [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
        marginLeft: '6px',
    },
}));

const NavItem = ({
    icon,
    title,
    to,
    selected = false,
    onSelect
}) => {
    return (
        <NavTooltip title={title} placement="right" arrow>
            <Link to={to} onClick={() => onSelect(to)}>
                <NavItemContainer selected={selected}>
                    <NavItemIcon>{icon({})}</NavItemIcon>
                    <NavItemTitle>{title}</NavItemTitle>
                </NavItemContainer>
            </Link>
        </NavTooltip>
    );
};

const NavItemContainer = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 72px;
    color: gray;

    &:hover {
        background-color: transparent;
        color: white;
    }

    ${({ selected }) =>
        selected &&
        `
            background-color: rgb(37, 38, 39);
            color: white;

            &:hover {
                background-color: rgb(37, 38, 39);
                color: white;
            }
        `
    }
`;

const NavItemIcon = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
`;

const NavItemTitle = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 10px;
`;

export default NavItem;