import { IntlProvider } from "react-intl";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "styled-components";

import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import CoursePage from "./pages/routes/CoursePage";
import HelpPage from "./pages/routes/HelpPage";

import TopBar from "./pages/TopBar";
import Sidebar, {
    COURSE_PATH,
    HELP_PATH,
} from "./pages/Sidebar";

import msgEn from "./lang/en.json";
import msgKo from "./lang/ko.json";

const langs = {
    ko: "ko",
};
let lang = localStorage.getItem("courseware_locale") ?? (navigator.userLanguage || navigator.language);
if(lang) {
    lang = lang.toLowerCase();
    const index = lang.indexOf('-');
    if(index >= 0) lang = lang.substring(0, index);
}
// lang = "en";
const locale = langs[lang] ?? "en";
const messages = {
    en: msgEn,
    ko: msgKo,
}[locale];

function App() {
  return (
    <IntlProvider locale={locale} messages={messages}>
        <Router>
            <AppContainer>
                <HeaderContainer>
                    <TopBar />
                </HeaderContainer>
                <BodyContainer>
                    <SidebarContainer>
                        <Sidebar />
                    </SidebarContainer>
                    <ContentContainer>
                        <Routes>
                            <Route path="/" element={<CoursePage />} />
                            <Route path={COURSE_PATH} element={<CoursePage />} />
                            <Route path={HELP_PATH} element={<HelpPage />} />
                        </Routes>
                    </ContentContainer>
                </BodyContainer>
            </AppContainer>
        </Router>
    </IntlProvider>
  );
}

const AppContainer = styled.div`
    width: 100vw;
    height: 100vh;
`;

const HeaderContainer = styled.div`
    z-index: 99;
`;

const BodyContainer = styled.div`
    width: 100vw;
    height: calc(100vh - 48px);
    display: flex;
    flex-direction: row;
`;

const SidebarContainer = styled.div`
    width: 72px;
`;

const ContentContainer = styled.div`
    width: calc(100vw - 72px);
`;

export default App;
