import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

const GoogleLoginButton = ({
    onLoginSuccess,
}) => {
    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
            <GoogleLogin
                size="large"
                width="400px"
                onSuccess={(credentialResponse) => {
                    try {
                        onLoginSuccess(jwtDecode(credentialResponse.credential));
                    } catch (err) {
                        console.error(err);
                    }
                }}
                onError={() => {
                    console.error("google login failed.");
                }}
            />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginButton;