import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import GoogleLoginButton from "./GoogleLoginButton";

const LoginDialog = ({
    open,
    onSuccess,
    onClickCancel,
}) => {
    const onGoogleLoginSuccess = (data) => {
        onSuccess(data);
    };

    const onCancel = () => {
        onClickCancel();
    };

    const onClose = (event, reason) => {
        if(reason === "escapeKeyDown" || reason === "backdropClick") return;
        onCancel();
    };

    return (
        <Dialog open={open} onClose={onClose} disableRestoreFocus={true}>
            <DialogTitle>
                <FormattedMessage id="topbar_login" />
            </DialogTitle>
            <DialogContent>
                <GoogleLoginButton onLoginSuccess={onGoogleLoginSuccess} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>
                    <FormattedMessage id="button_cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LoginDialog;