import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import NavItem from "./NavItem";
import styled from "styled-components";
import {
    FiList,
    FiHelpCircle,
    FiTrello,
    FiEdit,
    FiArchive,
    FiFilm,
    FiUser,
} from "react-icons/fi";

export const COURSE_PATH = "/course";
export const HELP_PATH = "/help";

const Sidebar = () => {
    const intl = useIntl();
    const location = useLocation();
    const navigate = useNavigate();

    const onSelectNav = (path) => {
        navigate(path);
    };

    return (
        <SidebarContainer>
            <SidebarItemContainer>
                <NavItem
                    icon={FiList}
                    title={intl.formatMessage({ id: "sidebar_menu_classroom" })}
                    to={COURSE_PATH}
                    selected={location.pathname === COURSE_PATH || location.pathname === "/" || !location.pathname}
                    onSelect={onSelectNav}
                />
                <NavItem
                    icon={FiHelpCircle}
                    title={intl.formatMessage({ id: "sidebar_menu_help" })}
                    to={HELP_PATH}
                    selected={location.pathname === HELP_PATH}
                    onSelect={onSelectNav}
                />
            </SidebarItemContainer>
        </SidebarContainer>
    );
};

const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    background-color: rgb(24, 25, 27);
    height: 100%;
    width: 72px;
    overflow: auto;
    border-right: 1px solid #181818;
`;

const SidebarItemContainer = styled.ul`
    width: 100%;
    margin: 0;
    padding: 0;
    color: #d7d7d7;
`;

export default Sidebar;