import Menu from "@mui/material/Menu";

const BubbleMenu = ({
    anchorEl,
    onClose,
    children,
    flip = false,
}) => {
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    "&:before": {
                        content: "''",
                        display: "block",
                        position: "absolute",
                        top: 0,
                        left: flip ? 14 : null,
                        right: flip ? null : 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: flip ? "left" : "right", vertical: "top" }}
            anchorOrigin={{ horizontal: flip ? "left" : "right", vertical: "bottom" }}
            keepMounted
        >
            {children}
        </Menu>
    );
};

export default BubbleMenu;